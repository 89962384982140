<template>
    <div>
        <h1>404</h1>
        <button @cilick="$router.back()">Back</button>
    </div>
</template>

<script>
export default {
    name: 'Error404',
};
</script>
